$range-slider-padding: rem-calc(10 0) !default;

$range-slider-inputs-margin: rem-calc(0 0 5) !default;
$range-slider-inputs-font: null !default;
$range-slider-inputs-letter-spacing: null !default;

$range-slider-thumb-background: color(primary) !default;
$range-slider-thumb-border: 3px solid color(primary) !default;
$range-slider-thumb-size: rem-calc(20) !default;
$range-slider-thumb-active-background: color(primary) !default;
$range-slider-thumb-lower-background: color(primary) !default;

$range-slider-track-background: color(element-background) !default;
$range-slider-track-background--large: null !default;
$range-slider-track-height: rem-calc(3) !default;

$range-slider-connect-background: color(primary) !default;

$range-slider-handle-size: rem-calc(18) !default;
$range-slider-handle-top: rem-calc(2) !default;

$range-slider-value-color: null !default;

@mixin lora-helper-nouislider-contrib {
    /* nouislider - 11.1.0 - 2018-04-02 11:18:13 */

    /* Functional styling;
     * These styles are required for noUiSlider to function.
     * You don't need to change these rules to apply your design.
     */
    .noUi-target,
    .noUi-target * {
        touch-action: none;
        user-select: none;
        box-sizing: border-box;
    }

    .noUi-target {
        position: relative;
        direction: ltr;
    }

    .noUi-base,
    .noUi-connects {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1; // stylelint-disable-line
    }

    /* Wrapper for all connect elements.
     */
    .noUi-connects {
        overflow: hidden;
        z-index: 0;
    }

    .noUi-connect,
    .noUi-origin {
        will-change: transform;
        position: absolute;
        z-index: 1; // stylelint-disable-line
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transform-origin: 0 0;
    }

    .noUi-origin {
        height: 10%;
        width: 10%;
    }

    /* Offset direction
     */
    html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
        left: auto;
        right: 0;
    }

    /* Give origins 0 height/width so they don't interfere with clicking the
     * connect elements.
     */
    .noUi-horizontal .noUi-origin {
        height: 0;
    }

    .noUi-handle {
        position: absolute;
    }

    .noUi-state-tap .noUi-connect,
    .noUi-state-tap .noUi-origin {
        transition: transform 0.3s;
    }

    .noUi-state-drag * {
        cursor: inherit !important; // stylelint-disable-line
    }

    /* Slider size and handle placement;
     */
    .noUi-horizontal {
        height: 18px;
    }

    .noUi-horizontal .noUi-handle {
        width: 34px;
        height: 28px;
        left: -17px;
        top: -6px;
    }

    html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
        right: -17px;
        left: auto;
    }
}

@mixin lora-helper-nouislider-theme {
    /* Styling;
     * Giving the connect element a border radius causes issues with using transform: scale
     */

    .noUi-base,
    .noUi-connects {
        height: auto;
        width: auto;
    }

    .noUi-base {
        padding: $range-slider-padding;
        margin: 0 #{$range-slider-thumb-size / 2};
    }

    .noUi-connects {
        background: $range-slider-track-background;
        border-radius: 3px;
        border: 0;
        box-sizing: border-box;
        cursor: pointer;
        height: $range-slider-track-height;
        margin: 0 -#{$range-slider-thumb-size / 2};
        position: relative;

        @include breakpoint(large) {
            background: $range-slider-track-background--large;
        }
    }

    .noUi-connect {
        background: $range-slider-connect-background;
    }

    /* Handles and cursors;
     */
    .noUi-draggable {
        cursor: ew-resize;
    }

    .noUi-handle {
        display: flex;
        align-items: center;
        justify-content: space-around;

        &::after {
            appearance: none;
            background: $range-slider-thumb-background;
            border-radius: 50%;
            border: $range-slider-thumb-border;
            box-sizing: border-box;
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            pointer-events: all;
            z-index: 1; // stylelint-disable-line
        }

        &.noUi-active {
            &::after {
                background: $range-slider-thumb-active-background;
            }
        }

        &.noUi-handle-lower {
            &::after {
                background: $range-slider-thumb-lower-background;
            }
        }
    }

    /* Disabled state;
     */
    [disabled] .noUi-connect {
        background: color(element-background);
    }

    [disabled].noUi-target,
    [disabled].noUi-handle,
    [disabled] .noUi-handle {
        cursor: not-allowed;
    }

    /* Horizontal layout;
     *
     */
    .noUi-horizontal {
        height: auto;
    }

    .noUi-horizontal .noUi-origin {
        top: $range-slider-padding;
    }

    .noUi-horizontal .noUi-handle {
        width: $range-slider-handle-size;
        height: $range-slider-handle-size;
        left: -#{$range-slider-handle-size / 2};
        top: $range-slider-handle-top;
        cursor: ew-resize;
    }

    html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
        right: -#{$range-slider-handle-size / 2};
        left: auto;
    }
}

@mixin lora-components-range-slider {
    .c-range-slider {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        position: relative;
        width: 100%;

        .c-range-slider__min-value,
        .c-range-slider__max-value {
            font: $range-slider-inputs-font;
            color: $range-slider-value-color;
            letter-spacing: $range-slider-inputs-letter-spacing;
        }
    }

    .c-range-slider__inputs {
        position: relative;
        margin: $range-slider-inputs-margin;
        width: 100%;
    }

    @include lora-helper-nouislider-contrib;
    @include lora-helper-nouislider-theme;
}
