@import "../../05-components/form/check-field";

$search-refinement-multi-select-list-style: none !default;
$search-refinement-multi-select-margin: 0 !default;
$search-refinement-multi-select-padding: 0 !default;
$search-refinement-multi-select-item-item-padding: rem-calc(14 0 0) !default;
$search-refinement-multi-select-item-link-color: color(text) !default;
$search-refinement-multi-select-item-link-hover-color: $search-refinement-multi-select-item-link-color !default;
$search-refinement-multi-select-item-link-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$search-refinement-multi-select-item-link-text-decoration: none !default;
$search-refinement-multi-select-item-link-text-transform: null !default;
$search-refinement-multi-select-item-link-icon-background: color(light) !default;
$search-refinement-multi-select-item-link-icon-border: 1px solid color(border) !default;
$search-refinement-multi-select-item-link-icon-color: color(light) !default;
$search-refinement-multi-select-item-link-icon-size: rem-calc(20) !default;
$search-refinement-multi-select-item-link-icon-margin: rem-calc(0 10 0 0) !default;
$search-refinement-multi-select-item-link-selected-text-color: null !default;
$search-refinement-multi-select-item-link-selected-text-font: null !default;

@mixin lora-components-refinement-multi-select {
    .c-refinement-multi-select {
        list-style: none;
        margin: $search-refinement-multi-select-margin;
        padding: $search-refinement-multi-select-padding;
    }

    .c-refinement-multi-select__item + .c-refinement-multi-select__item {
        padding: $search-refinement-multi-select-item-item-padding;
    }

    .c-refinement-multi-select__item-link {
        color: $search-refinement-multi-select-item-link-color;
        display: inline-block;
        cursor: pointer;
        text-decoration: $search-refinement-multi-select-item-link-text-decoration;
        position: relative;
        font: $search-refinement-multi-select-item-link-font;
        outline-width: 0;
        text-transform: $search-refinement-multi-select-item-link-text-transform;

        &:hover,
        &:focus {
            color: $search-refinement-multi-select-item-link-hover-color;
        }

        &.m-selected {
            .c-refinement-multi-select__item-text {
                color: $search-refinement-multi-select-item-link-selected-text-color;
                font: $search-refinement-multi-select-item-link-selected-text-font;
            }
        }
    }
}
