@import "../button-group";
@import "../scrollbar";

$search-refinements-padding: rem-calc(0 0 40) !default;
$search-refinements-padding--large: rem-calc(7 0 0) !default;
$search-refinements-border-top: none !default;
// Category Name
$search-refinements-category-name-separator: 1px solid color(border) !default;
$search-refinements-category-name-margin: rem-calc(0 0 5) !default;
$search-refinements-category-name-padding: rem-calc(0 0 10) !default;
$search-refinements-category-name-font: 500 #{rem-calc(18)} / 1.25 $font-primary !default;
$search-refinements-category-name-color: color(primary) !default;
$search-refinements-category-name-text-transform: uppercase !default;
$search-refinements-category-name-border-top: none !default;
// Header
$search-refinements-header-background: transparent !default;
$search-refinements-header-border-bottom: 2px solid color(border) !default;
$search-refinements-header-height: rem-calc(50) !default;
$search-refinements-header-padding: rem-calc(0 15) !default;
$search-refinements-header-padding--large: rem-calc(10 0) !default;
$search-refinements-header-margin--large: 0 !default;
$search-refinements-header-title-color: color(dark) !default;
$search-refinements-header-title-font: bold #{rem-calc(14)}/1.3 $font-primary !default;
$search-refinements-header-title-font--large: bold rem-calc(14) / normal $font-primary !default;
$search-refinements-header-title-margin: 0 !default;
$search-refinements-header-title-margin--large: 0 !default;
$search-refinements-header-title-text-align: left !default;
$search-refinements-header-title-text-transform: uppercase !default;
$search-refinements-header-title-text-transform--large: null !default;
$search-refinements-header-title-padding--large: rem-calc(0 10 0 0) !default;
$search-refinements-header-back-and-results-color: color(dark) !default;
$search-refinements-header-back-and-results-font: #{rem-calc(14)} / normal $font-primary !default;
$search-refinements-header-back-and-results-text-transform: none !default;
$search-refinements-header-back-and-results-letter-spacing: null !default;
$search-refinements-header-back-and-results-text-decoration: underline !default;
$search-refinements-header-back-icon: "cross" !default;
$search-refinements-header-back-icon-color: color(dark) !default;
$search-refinements-header-back-icon-hover-color: $search-refinements-header-back-icon-color !default;
$search-refinements-header-back-icon-margin: rem-calc(8) !default;
$search-refinements-header-back-icon-size: rem-calc(16) !default;
$search-refinements-header-border-top--large: none !default;
$search-refinements-header-reset-padding: rem-calc(8 12) !default;
$search-refinements-header-count-background--large: color(primary) !default;
$search-refinements-header-count-border-radius--large: 50% !default;
$search-refinements-header-count-border--large: null !default;
$search-refinements-header-count-bottom--large: 50% !default;
$search-refinements-header-count-transform--large: translateY(-50%) !default;
$search-refinements-header-count-color--large: color(light) !default;
$search-refinements-header-count-font-size--large: rem-calc(12) !default;
$search-refinements-header-count-line-height--large: rem-calc(20) !default;
$search-refinements-header-count-left--large: rem-calc(75) !default;
$search-refinements-header-count-width--large: rem-calc(20) !default;
// Footer
$search-refinements-footer-height: rem-calc(40) !default;
$search-refinements-footer-margin: 0 !default;
$search-refinements-footer-padding: rem-calc(8 15) !default;
$search-refinements-footer-box-shadow: rem-calc(0 -1 8 0) rgba(color(dark), 0.15) !default;
$search-refinements-footer-background: color(light) !default;
$search-refinements-footer-button-spacing: rem-calc(15) !default;
// Action buttons
$search-refinements-apply-border-left: 1px solid color(light) !default;
// Refinements in a Sliding panel styles
$search-refinements-sliding-panel-padding: rem-calc(0 0 55) !default;
$search-refinements-sliding-panel-padding--large: null !default;
$search-refinements-sliding-panel-width--large: rem-calc(400) !default;
$search-refinements-sliding-panel-header-padding--large: rem-calc(20 15) !default;
$search-refinements-sliding-panel-header-title-font: bold rem-calc(18) / normal $font-primary !default;
$search-refinements-sliding-panel-header-title-font--large: bold rem-calc(18) / normal $font-primary !default;
$search-refinements-sliding-panel-header-title-text-transform: none !default;

@mixin lora-components-refinements {
    .c-refinements {
        display: flex;
        flex-flow: column nowrap;
        padding: $search-refinements-padding;
        border-top: $search-refinements-border-top;

        @include breakpoint(medium down) {
            height: 100%;
            position: relative;
        }
        @include breakpoint(large) {
            padding: $search-refinements-padding--large;
        }

        .c-refinement-category {
            @include show-for(large);
        }
    }

    .c-refinements__content {
        @include breakpoint(medium down) {
            height: 100%;
            overflow: auto;
        }
    }

    .c-refinements__category-name {
        border-bottom: $search-refinements-category-name-separator;
        border-top: $search-refinements-category-name-border-top;
        color: $search-refinements-category-name-color;
        font: $search-refinements-category-name-font;
        margin: $search-refinements-category-name-margin;
        padding: $search-refinements-category-name-padding;
        text-transform: $search-refinements-category-name-text-transform;
    }

    .c-refinements__header {
        background: $search-refinements-header-background;

        @include breakpoint(medium down) {
            height: $search-refinements-header-height;
            padding: $search-refinements-header-padding;
            border-bottom: $search-refinements-header-border-bottom;
        }
        @include breakpoint(large) {
            padding: $search-refinements-header-padding--large;
            margin: $search-refinements-header-margin--large;
            border-top: $search-refinements-header-border-top--large;
            position: relative;
            z-index: 1; // stylelint-disable-line
        }
    }

    .c-refinements__header-back {
        @include lora-helper-button-icon-base;
        @include lora-helper-button-icon(
            $left: false,
            $icon: $search-refinements-header-back-icon,
            $color: $search-refinements-header-back-icon-color,
            $color-hover: $search-refinements-header-back-icon-hover-color,
            $margin: $search-refinements-header-back-icon-margin,
            $size: $search-refinements-header-back-icon-size
        );

        color: $search-refinements-header-back-and-results-color;
        cursor: pointer;
        flex: 0 0 1%;
        font: $search-refinements-header-back-and-results-font;
        white-space: nowrap;
        text-transform: $search-refinements-header-back-and-results-text-transform;
        letter-spacing: $search-refinements-header-back-and-results-letter-spacing;
        text-decoration: $search-refinements-header-back-and-results-text-decoration;

        .c-refinements:not(.m-sliding-panel) & {
            @include hide-for(large);
        }
    }

    .c-refinements__header-reset {
        padding: $search-refinements-header-reset-padding;
        margin-left: auto;
    }

    .c-refinements__header-refinements-count {
        @include breakpoint(large) {
            background: $search-refinements-header-count-background--large;
            border-radius: $search-refinements-header-count-border-radius--large;
            border: $search-refinements-header-count-border--large;
            color: $search-refinements-header-count-color--large;
            font-size: $search-refinements-header-count-font-size--large;
            line-height: $search-refinements-header-count-line-height--large;
            text-align: center;
            width: $search-refinements-header-count-width--large;
        }
    }

    .c-refinements__header-title {
        font: $search-refinements-header-title-font;
        margin: $search-refinements-header-title-margin;
        text-transform: $search-refinements-header-title-text-transform;

        @include breakpoint(medium down) {
            color: $search-refinements-header-title-color;
            flex: 1 0 auto;
            text-align: $search-refinements-header-title-text-align;
        }
        @include breakpoint(large) {
            font: $search-refinements-header-title-font--large;
            margin: $search-refinements-header-title-margin--large;
            text-transform: $search-refinements-header-title-text-transform--large;
            padding: $search-refinements-header-title-padding--large;
        }
    }

    .c-refinements__footer {
        @include lora-helper-button-group($spacing: $search-refinements-footer-button-spacing);

        bottom: 0;
        #{$global-left}: 0;
        position: absolute;
        width: 100%;
        margin: $search-refinements-footer-margin;
        padding: $search-refinements-footer-padding;
        box-shadow: $search-refinements-footer-box-shadow;
        background: $search-refinements-footer-background;

        .c-refinements:not(.m-sliding-panel) & {
            @include hide-for(large);
        }
    }

    .c-refinements.m-sliding-panel {
        padding: $search-refinements-sliding-panel-padding;
        height: 100%;

        @include breakpoint(large) {
            width: $search-refinements-sliding-panel-width--large;
            padding: $search-refinements-sliding-panel-padding--large;
        }

        .c-refinement-category,
        .c-refinements__header-refinements-count,
        .c-refinements__header-reset {
            display: none;
        }

        .c-refinements__header {
            padding: $search-refinements-sliding-panel-header-padding--large;
        }

        .c-refinements__content {
            @include breakpoint(large) {
                @include lora-helper-scrollbar-vertical;

                overflow-y: auto;
                height: 100%;
            }
        }

        .c-refinements__header-title {
            flex: 1 0 auto;
            font: $search-refinements-sliding-panel-header-title-font;
            text-transform: $search-refinements-sliding-panel-header-title-text-transform;

            @include breakpoint(large) {
                font: $search-refinements-sliding-panel-header-title-font--large;
            }
        }
    }
}
