@import "button";
@import "button-group";
// Deprecated components
@import "../100-deprecated/kit-builder/kit-builder-selectedproducts";

// Info
$selected-products-info-margin: $kit-builder-selectedproducts-info-margin !default;
$selected-products-info-margin--large: $kit-builder-selectedproducts-info-margin--large !default;
$selected-products-info-justify-content: $kit-builder-selectedproducts-info-justify-content !default;
$selected-products-info-justify-content--large: $kit-builder-selectedproducts-info-justify-content--large !default;
/// Title
$selected-products-title-font: $kit-builder-selectedproducts-title-font !default;
$selected-products-title-font--large: $kit-builder-selectedproducts-title-font--large !default;
$selected-products-title-color: $kit-builder-selectedproducts-title-color !default;
$selected-products-title-color--large: $kit-builder-selectedproducts-title-color--large !default;
$selected-products-title-text-transform: $kit-builder-selectedproducts-title-text-transform !default;
$selected-products-title-text-transform--large: $kit-builder-selectedproducts-title-text-transform--large !default;
$selected-products-title-margin: $kit-builder-selectedproducts-title-margin !default;
$selected-products-title-margin--large: $kit-builder-selectedproducts-title-margin--large !default;
$selected-products-title-text-align: null !default;
$selected-products-title-text-align--large: null !default;
/// Total
$selected-products-total-justify-content: $kit-builder-selectedproducts-total-justify-content !default;
$selected-products-total-justify-content--large: $kit-builder-selectedproducts-total-justify-content--large !default;
$selected-products-total-padding: $kit-builder-selectedproducts-total-padding !default;
$selected-products-total-padding--large: $kit-builder-selectedproducts-total-padding--large !default;
$selected-products-total-background: $kit-builder-selectedproducts-total-background !default;
$selected-products-total-background--large: $kit-builder-selectedproducts-total-background--large !default;
$selected-products-total-font: $kit-builder-selectedproducts-total-font !default;
$selected-products-total-font--large: $kit-builder-selectedproducts-total-font--large !default;
$selected-products-total-color: $kit-builder-selectedproducts-total-color !default;
$selected-products-total-color--large: $kit-builder-selectedproducts-total-color--large !default;
$selected-products-total-text-transform: $kit-builder-selectedproducts-total-text-transform !default;
$selected-products-total-text-transform--large: $kit-builder-selectedproducts-total-text-transform--large !default;
$selected-products-total-margin: $kit-builder-selectedproducts-total-margin !default;
$selected-products-total-margin--large: $kit-builder-selectedproducts-total-margin--large !default;
$selected-products-total-letter-spacing: null !default;
// Actions
$selected-products-actions-margin: $kit-builder-selectedproducts-actions-margin !default;
$selected-products-actions-margin--large: $kit-builder-selectedproducts-actions-margin--large !default;
//message
$selected-products-messsage-margin: rem-calc(10 0 0) !default;
// Carousel
$selected-products-carousel-margin: $kit-builder-selectedproducts-carousel-margin !default;
$selected-products-carousel-margin--large: $kit-builder-selectedproducts-carousel-margin--large !default;
/// Error
$selected-products-error-flex--large: $kit-builder-selectedproducts-error-flex--large !default;
$selected-products-error-margin: $kit-builder-selectedproducts-error-margin !default;
$selected-products-error-margin--large: $kit-builder-selectedproducts-error-margin--large !default;
$selected-products-error-order--large: $kit-builder-selectedproducts-error-order--large !default;
$selected-products-error-text-align: $kit-builder-selectedproducts-error-text-align !default;
$selected-products-error-text-align--large: $kit-builder-selectedproducts-error-text-align--large !default;
/// Item
$selected-products-item-border: $kit-builder-selectedproducts-item-border !default;
$selected-products-item-border--large: $kit-builder-selectedproducts-item-border--large !default;
$selected-products-item-margin: $kit-builder-selectedproducts-item-margin !default;
$selected-products-item-margin--large: $kit-builder-selectedproducts-item-margin--large !default;
$selected-products-item-border-radius: null !default;
$selected-products-item-border-radius--large: $selected-products-item-border-radius !default;
$selected-products-item-active-border-radius: null !default;
$selected-products-item-active-border-radius--large: $selected-products-item-active-border-radius !default;
/// Item active
$selected-products-item-active-border: $kit-builder-selectedproducts-item-active-border !default;
$selected-products-item-active-border--large: $kit-builder-selectedproducts-item-active-border--large !default;
/// Item remove
$selected-products-item-remove-position: $kit-builder-selectedproducts-item-remove-position !default;
$selected-products-item-remove-right: $kit-builder-selectedproducts-item-remove-right !default;
$selected-products-item-remove-top: $kit-builder-selectedproducts-item-remove-top !default;
$selected-products-item-remove-padding: $kit-builder-selectedproducts-item-remove-padding !default;
$selected-products-item-remove-icon: $kit-builder-selectedproducts-item-remove-icon !default;
$selected-products-item-remove-icon-color: $kit-builder-selectedproducts-item-remove-icon-color !default;
$selected-products-item-remove-icon-hover-color: $kit-builder-selectedproducts-item-remove-icon-hover-color !default;
$selected-products-item-remove-icon-height: $kit-builder-selectedproducts-item-remove-icon-height !default;
$selected-products-item-remove-icon-height--large: $kit-builder-selectedproducts-item-remove-icon-height--large !default;
$selected-products-item-remove-icon-width: $kit-builder-selectedproducts-item-remove-icon-width !default;
$selected-products-item-remove-icon-width--large: $kit-builder-selectedproducts-item-remove-icon-width--large !default;
$selected-products-item-remove-icon-usesvg: false !default;
$selected-products-item-remove-filter: drop-shadow(-1px 0 0 color(light)) drop-shadow(0 -1px 0 color(light)) drop-shadow(1px 0 0 color(light)) drop-shadow(0 1px 0 color(light)) !default;
// Simplified panel without product thumbnails
$selected-products-no-products-justify-content--large: space-between !default;

@mixin lora-components-selected-products {
    .c-selected-products__info {
        display: flex;
        flex-wrap: wrap;
        margin: $selected-products-info-margin;
        justify-content: $selected-products-info-justify-content;

        @include breakpoint(large) {
            margin: $selected-products-info-margin--large;
            justify-content: $selected-products-info-justify-content--large;
        }
    }

    .c-selected-products__title {
        @include text-style(
            $color: (
                small: $selected-products-title-color,
                large: $selected-products-title-color--large
            ),
            $font: (
                small: $selected-products-title-font,
                large: $selected-products-title-font--large
            ),
            $margin: (
                small: $selected-products-title-margin,
                large: $selected-products-title-margin--large
            ),
            $text-transform: (
                small: $selected-products-title-text-transform,
                large: $selected-products-title-text-transform--large
            ),
            $text-align: (
                small: $selected-products-title-text-align,
                large: $selected-products-title-text-align--large
            )
        );
    }

    .c-selected-products__total {
        @include text-style(
            $color: (
                small: $selected-products-total-color,
                large: $selected-products-total-color--large
            ),
            $font: (
                small: $selected-products-total-font,
                large: $selected-products-total-font--large
            ),
            $margin: (
                small: $selected-products-total-margin,
                large: $selected-products-total-margin--large
            ),
            $text-transform: (
                small: $selected-products-total-text-transform,
                large: $selected-products-total-text-transform--large
            ),
            $padding: (
                small: $selected-products-total-padding,
                large: $selected-products-total-padding--large
            ),
            $letter-spacing: (
                small: $selected-products-total-letter-spacing
            )
        );

        display: flex;
        justify-content: $selected-products-total-justify-content;
        background: $selected-products-total-background;

        @include breakpoint(large) {
            justify-content: $selected-products-total-justify-content;
            background: $selected-products-total-background--large;
        }
    }

    .c-selected-products__carousel {
        margin: $selected-products-carousel-margin;

        @include breakpoint(large) {
            margin: $selected-products-carousel-margin--large;
        }
    }

    .c-selected-products__actions {
        margin: $selected-products-actions-margin;

        @include breakpoint(large) {
            display: flex;
            flex-flow: column wrap;
            margin: $selected-products-actions-margin--large;
        }
    }

    .c-selected-products__button-group {
        @include lora-helper-button-group('.c-selected-products__button-group-item', 0, 0);

        @include breakpoint(medium down) {
            @include lora-helper-button-group-expand('.c-selected-products__button-group-item');
        }
    }

    .c-selected-products__message {
        margin: $selected-products-messsage-margin;
    }

    .c-selected-products__error {
        margin: $selected-products-error-margin;
        text-align: $selected-products-error-text-align;

        @include breakpoint(large) {
            flex: $selected-products-error-flex--large;
            margin: $selected-products-error-margin--large;
            order: $selected-products-error-order--large;
            text-align: $selected-products-error-text-align--large;
        }
    }

    .c-selected-products__item {
        position: relative;
        display: block;
        border: $selected-products-item-border;
        border-radius: $selected-products-item-border-radius;
        margin: $selected-products-item-margin;
        padding-bottom: 100%;
        width: 100%;
        height: 0;
        overflow: hidden;
        transform: translateZ(0);

        .c-selected-products__image {
            @include absolute-center;

            height: 100%;
            width: 100%;
            max-width: none;
            z-index: -1; // fix transform issue for image with resize calculation
            object-fit: cover;
        }

        @include breakpoint(large) {
            border: $selected-products-item-border--large;
            border-radius: $selected-products-item-border-radius--large;
            margin: $selected-products-item-margin--large;
        }

        &.m-active {
            border: $selected-products-item-active-border;
            border-radius: $selected-products-item-active-border-radius;

            @include breakpoint(large) {
                border: $selected-products-item-active-border--large;
                border-radius: $selected-products-item-active-border-radius--large;
            }

            .c-selected-products__item-remove {
                display: block;
            }
        }
    }

    .c-selected-products__item-remove {
        position: $selected-products-item-remove-position;
        #{$global-right}: $selected-products-item-remove-right;
        top: $selected-products-item-remove-top;
        padding: $selected-products-item-remove-padding;
        cursor: pointer;
        display: none;

        &::after {
            @include svg-icon($selected-products-item-remove-icon, $selected-products-item-remove-icon-color, 100%, center, no-repeat, $usesvg: $selected-products-item-remove-icon-usesvg);

            content: '';
            display: block;
            height: $selected-products-item-remove-icon-height;
            width: $selected-products-item-remove-icon-width;
            filter: $selected-products-item-remove-filter;

            @include breakpoint(large) {
                height: $selected-products-item-remove-icon-height--large;
                width: $selected-products-item-remove-icon-width--large;
            }
        }

        &:hover {
            &::after {
                @include svg-icon($selected-products-item-remove-icon, $selected-products-item-remove-icon-hover-color, 100%, center, no-repeat, $usesvg: $selected-products-item-remove-icon-usesvg, $color-change: true);
            }
        }
    }

    // Simplified panel without product thumbnails
    .c-selected-products.m-no-products {
        @include breakpoint(large) {
            display: flex;
            justify-content: $selected-products-no-products-justify-content--large;
        }

        .c-selected-products__actions {
            margin: 0;
        }
    }
}
