// Compare table
$product-table-compare-border-collapse: collapse !default;
$product-table-compare-border-spacing: null !default;
// Cell
$product-table-compare-cell-vertical-align: top !default;
$product-table-compare-cell-padding: rem-calc(10) !default;
$product-table-compare-cell-padding--large: rem-calc(12 15) !default;
$product-table-compare-cell-width: rem-calc(170) !default;
$product-table-compare-cell-width--large: rem-calc(206) !default;
$product-table-compare-cell-min-width: $product-table-compare-cell-width !default;
$product-table-compare-cell-min-width--large: $product-table-compare-cell-width--large !default;
$product-table-compare-cell-font: #{rem-calc(11)} / 1.5 $font-primary !default;
$product-table-compare-cell-font--large: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-table-compare-cell-border-left: null !default;
$product-table-compare-cell-first-child-border-left: null !default;
$product-table-compare-cell-add-more-border-left: null !default;
$product-table-compare-cell-text-transform: null !default;
// Compare table secondary. Page context (PDP)
$product-table-compare-secondary-columns--large: 4 !default;
$product-table-compare-secondary-cell-padding--large: rem-calc(12 40) !default;
// Remove
$product-table-compare-remove-margin: rem-calc(0 -12 0 0) !default;
$product-table-compare-remove-font: null !default;
$product-table-compare-remove-icon-size: rem-calc(10) !default;
$product-table-compare-remove-icon: cross !default;
$product-table-compare-remove-icon-color: color(primary-active) !default;
$product-table-compare-remove-icon-margin: rem-calc(0 0 0 6) !default;
$product-table-compare-remove-icon-display: true !default;
$product-table-compare-remove-icon-usesvg: true !default;
// Add more
$product-table-compare-addmore-border: null !default;
$product-table-compare-addmore-font: #{rem-calc(11)} / 1.5 $font-primary !default;
$product-table-compare-addmore-font--large: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-table-compare-addmore-margin: rem-calc(33 0 0) !default;
$product-table-compare-addmore-margin--large: null !default;
$product-table-compare-addmore-padding: rem-calc(20 10) !default;
$product-table-compare-addmore-padding--large: rem-calc(33 20 25) !default;
$product-table-compare-addmore-icon-size: rem-calc(60) !default;
$product-table-compare-addmore-icon-size--large: rem-calc(80) !default;
$product-table-compare-addmore-icon: plus !default;
$product-table-compare-addmore-icon-usesvg: false !default;
$product-table-compare-addmore-icon-hover: $product-table-compare-addmore-icon !default;
$product-table-compare-addmore-icon-hover-usesvg: false !default;
$product-table-compare-addmore-icon-color: color(primary) !default;
$product-table-compare-addmore-icon-color-hover: color(primary-active) !default;
$product-table-compare-addmore-icon-margin: 0 auto rem-calc(10) !default;
$product-table-compare-addmore-icon-background-color: color(light) !default;
$product-table-compare-addmore-icon-border: rem-calc(17) solid transparent !default;
$product-table-compare-addmore-icon-border--large: rem-calc(23) solid transparent !default;
$product-table-compare-addmore-icon-border-radius: 50% !default;
// Label
$product-table-compare-cell-label-font: #{rem-calc(11)} / 1.5 $font-primary !default;
$product-table-compare-cell-label-font--large: null !default;
$product-table-compare-cell-label-border-bottom: 1px solid color(text) !default;
$product-table-compare-cell-label-padding: rem-calc(0 0 5) !default;
$product-table-compare-cell-label-margin: rem-calc(0 0 12) !default;
$product-table-compare-cell-label-text-transform: null !default;
$product-table-compare-cell-label-letter-spacing: null !default;

@mixin lora-components-product-table-compare {
    .c-product-table-compare {
        border-collapse: $product-table-compare-border-collapse;
        border-spacing: $product-table-compare-border-spacing;
    }

    .c-product-table-compare.m-secondary {
        .c-product-table-compare__cell {
            @include breakpoint(large) {
                max-width: rem-to-px(($grid-container - map-get($grid-container-padding, large)) / $product-table-compare-secondary-columns--large);
                min-width: rem-to-px(($grid-container - map-get($grid-container-padding, large)) / $product-table-compare-secondary-columns--large);
                padding: $product-table-compare-secondary-cell-padding--large;
            }
        }
    }

    .c-product-table-compare__cell {
        max-width: $product-table-compare-cell-width;
        min-width: $product-table-compare-cell-min-width;
        padding: $product-table-compare-cell-padding;
        vertical-align: $product-table-compare-cell-vertical-align;
        font: $product-table-compare-cell-font;
        border-left: $product-table-compare-cell-border-left;
        text-transform: $product-table-compare-cell-text-transform;

        @include breakpoint(large) {
            max-width: $product-table-compare-cell-width--large;
            min-width: $product-table-compare-cell-min-width--large;
            padding: $product-table-compare-cell-padding--large;
            font: $product-table-compare-cell-font--large;
        }

        &:first-child {
            border-left: $product-table-compare-cell-first-child-border-left;
        }
        
        &.m-add-more {
            border-left: $product-table-compare-cell-add-more-border-left;
        }
    }

    .c-product-table-compare__remove {
        margin: $product-table-compare-remove-margin;
        font: $product-table-compare-remove-font;

        &::after {
            @include svg-icon($product-table-compare-remove-icon, $product-table-compare-remove-icon-color, 100%, left, no-repeat, $usesvg: $product-table-compare-remove-icon-usesvg);

            content: '';
            height: $product-table-compare-remove-icon-size;
            margin: $product-table-compare-remove-icon-margin;
            width: $product-table-compare-remove-icon-size;

            @if ($product-table-compare-remove-icon-display) {
                display: inline-block;
            }
            @else {
                display: none;
            }
        }
    }

    .c-product-table-compare__addmore {
        display: block;
        width: 100%;
        cursor: pointer;
        font: $product-table-compare-addmore-font;
        margin: $product-table-compare-addmore-margin;
        padding: $product-table-compare-addmore-padding;
        border: $product-table-compare-addmore-border;

        @include breakpoint(large) {
            font: $product-table-compare-addmore-font--large;
            margin: $product-table-compare-addmore-margin--large;
            padding: $product-table-compare-addmore-padding--large;
        }

        &::before {
            @include svg-icon($product-table-compare-addmore-icon, $product-table-compare-addmore-icon-color, 100%, left, no-repeat, $usesvg: $product-table-compare-addmore-icon-usesvg);

            content: '';
            display: block;
            height: $product-table-compare-addmore-icon-size;
            margin: $product-table-compare-addmore-icon-margin;
            width: $product-table-compare-addmore-icon-size;
            border: $product-table-compare-addmore-icon-border;
            background-color: $product-table-compare-addmore-icon-background-color;
            border-radius: $product-table-compare-addmore-icon-border-radius;

            @include breakpoint(large) {
                height: $product-table-compare-addmore-icon-size--large;
                width: $product-table-compare-addmore-icon-size--large;
                border: $product-table-compare-addmore-icon-border--large;
            }
        }

        &:hover {
            &::before {
                @include svg-icon($product-table-compare-addmore-icon-hover, $product-table-compare-addmore-icon-color-hover, 100%, left, no-repeat, $usesvg: $product-table-compare-addmore-icon-hover-usesvg, $color-change: true);
            }
        }
    }

    .c-product-table-compare__cell-label {
        display: block;
        font: $product-table-compare-cell-label-font;
        padding: $product-table-compare-cell-label-padding;
        margin: $product-table-compare-cell-label-margin;
        border-bottom: $product-table-compare-cell-label-border-bottom;
        text-transform: $product-table-compare-cell-label-text-transform;
        letter-spacing: $product-table-compare-cell-label-letter-spacing;
    }
}
