$toggle-panel-background: color(global-background) !default;
$toggle-panel-padding: rem-calc(20 0) !default;
// Toggle button
$toggle-panel-button-background: $toggle-panel-background !default;
$toggle-panel-button-border-radius: 50% !default;
$toggle-panel-button-height: rem-calc(38) !default;
$toggle-panel-button-width: rem-calc(38) !default;
$toggle-panel-button-top: $toggle-panel-button-height / 2 !default;
$toggle-panel-button-icon: "chevron2-top" !default;
$toggle-panel-button-icon-color: color(primary) !default;
$toggle-panel-button-icon-hover-color: color(primary-active) !default;
$toggle-panel-button-hover-background: null !default;
$toggle-panel-button-icon-height: rem-calc(8) !default;
$toggle-panel-button-icon-width: rem-calc(14) !default;
$toggle-panel-button-icon-usesvg: true !default;
// Title
$toggle-panel-title-font: 700 #{rem-calc(14)} / 1.4 $font-primary !default;
$toggle-panel-title-font--large: null !default;
$toggle-panel-title-color: color(text) !default;
$toggle-panel-title-color--large: null !default;
$toggle-panel-title-text-transform: none !default;
$toggle-panel-title-text-transform--large: null !default;
$toggle-panel-title-margin: rem-calc(0 0 10) !default;
$toggle-panel-title-margin--large: 0 !default;

@mixin lora-helper-toggle-panel-button {
    @include horizontal-center;

    background: $toggle-panel-button-background;
    border-radius: $toggle-panel-button-border-radius;
    height: $toggle-panel-button-height;
    width: $toggle-panel-button-width;
    top: -$toggle-panel-button-top;
    cursor: pointer;

    &::after {
        @include absolute-center;
        @include svg-icon($toggle-panel-button-icon, $toggle-panel-button-icon-color, 100%, center, no-repeat, $usesvg: $toggle-panel-button-icon-usesvg);

        content: '';
        height: $toggle-panel-button-icon-height;
        width: $toggle-panel-button-icon-width;
    }

    &:hover,
    &:focus {
        background: $toggle-panel-button-hover-background;

        &::after {
            @include svg-icon($toggle-panel-button-icon, $toggle-panel-button-icon-hover-color, 100%, center, no-repeat, $color-change: true, $usesvg: $toggle-panel-button-icon-usesvg);
        }
    }
}

@mixin lora-helper-toggle-panel-button-expanded {
    transform: translateX(-50%) rotate(-180deg);
}

@mixin lora-helper-toggle-panel-container {
    overflow: hidden;
    height: 0;
    visibility: hidden;
}

@mixin lora-helper-toggle-panel-container-expanded {
    height: auto;
    overflow: visible;
    visibility: visible;
}

@mixin lora-components-toggle-panel {
    .c-toggle-panel {
        position: relative;
        background: $toggle-panel-background;
        padding: $toggle-panel-padding;

        &.m-fullwidth {
            @include pseudo-off-the-isle(inherit);
        }
    }

    .c-toggle-panel.m-expanded {
        .c-toggle-panel__container {
            @include lora-helper-toggle-panel-container-expanded;
        }

        .c-toggle-panel__button {
            @include lora-helper-toggle-panel-button-expanded;
        }
    }

    .c-toggle-panel__title {
        @include text-style(
            $color: (
                small: $toggle-panel-title-color,
                large: $toggle-panel-title-color--large
            ),
            $font: (
                small: $toggle-panel-title-font,
                large: $toggle-panel-title-font--large
            ),
            $margin: (
                small: $toggle-panel-title-margin,
                large: $toggle-panel-title-margin--large
            ),
            $text-transform: (
                small: $toggle-panel-title-text-transform,
                large: $toggle-panel-title-text-transform--large
            )
        );
    }

    .c-toggle-panel__button {
        @include lora-helper-toggle-panel-button;
    }

    .c-toggle-panel__container {
        @include lora-helper-toggle-panel-container;
    }
}
