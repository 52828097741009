$pagination-item-color: color(text) !default;
$pagination-item-hover-color: $pagination-item-color !default;
$pagination-item-active-color: color(light) !default;
$pagination-item-dots-width: rem-calc(22) !default;
$pagination-item-dots-margin--large: 0 !default;
$pagination-item-all-text-decoration: none !default;
$pagination-item-all-text-transform: null !default;
$pagination-item-all-font: null !default;
$pagination-item-all-color: null !default;
$pagination-item-all-margin: rem-calc(0 0 0 6) !default;
$pagination-item-all-margin--large: $pagination-item-all-margin !default;
$pagination-item-font-size: rem-calc(12) !default;
$pagination-item-font-weight: null !default;
$pagination-item-font-family: $font-primary !default;
$pagination-item-font: $pagination-item-font-weight #{$pagination-item-font-size} $pagination-item-font-family !default;
$pagination-item-padding: rem-calc(13) !default;
$pagination-item-margin: rem-calc(0) !default;
$pagination-item-margin--large: rem-calc(0 4) !default;
$pagination-item-active-background: color(secondary) !default;
$pagination-item-hover-background: color(element-background) !default;
$pagination-item-radius: 50% !default;
$pagination-size: rem-calc(35) !default; // can be null if not size is required
$pagination-loading-height: rem-calc(100) !default;
$pagination-item-border: null !default;

@mixin lora-components-pagination {
    .c-pagination {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .c-pagination.m-loading {
        position: relative;
        height: $pagination-loading-height;
    }

    .c-pagination__item {
        color: $pagination-item-color;
        font: $pagination-item-font;
        margin: $pagination-item-margin;
        text-decoration: none;

        @include breakpoint(large) {
            margin: $pagination-item-margin--large;
        }

        &.m-number,
        &.m-active,
        &.m-dots {
            @if has-value($pagination-size) {
                align-items: center;
                border-radius: $pagination-item-radius;
                display: flex;
                justify-content: center;
                height: $pagination-size;
                width: $pagination-size;
            }
        }

        &.m-active {
            @if has-value($pagination-size) {
                background: $pagination-item-active-background;
                color: $pagination-item-active-color;
                border: $pagination-item-border;
            }
        }

        &.m-number {
            &:hover {
                @if has-value($pagination-size) {
                    background: $pagination-item-hover-background;
                    color: $pagination-item-hover-color;
                }
            }
        }

        &.m-dots {
            width: $pagination-item-dots-width;

            @include breakpoint(large) {
                margin: $pagination-item-dots-margin--large;
            }
        }

        &.m-all {
            text-decoration: $pagination-item-all-text-decoration;
            text-transform: $pagination-item-all-text-transform;
            font: $pagination-item-all-font;
            color: $pagination-item-all-color;
            margin: $pagination-item-all-margin;

            @include breakpoint(large) {
                margin: $pagination-item-all-margin--large;
            }
        }
    }
}
