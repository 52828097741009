// Could be used by NGL-based brands before 16.0.0 release
// Variables migrated to 05-components/_selected-products.scss

// Info
$kit-builder-selectedproducts-info-margin: 0 !default; // @new: $selected-products-info-margin: $kit-builder-selectedproducts-info-margin !default;
$kit-builder-selectedproducts-info-margin--large: null !default; // @new: $selected-products-info-margin--large: $kit-builder-selectedproducts-info-margin--large !default;
$kit-builder-selectedproducts-info-justify-content: null !default; // @new: $selected-products-info-justify-content: $kit-builder-selectedproducts-info-justify-content !default;
$kit-builder-selectedproducts-info-justify-content--large: null !default; // @new: $selected-products-info-justify-content--large: $kit-builder-selectedproducts-info-justify-content--large !default;
/// Title
$kit-builder-selectedproducts-title-font: 700 #{rem-calc(14)} / 1.4 $font-primary !default; // @new: $selected-products-title-font: $kit-builder-selectedproducts-title-font !default;
$kit-builder-selectedproducts-title-font--large: null !default; // @new: $selected-products-title-font--large: $kit-builder-selectedproducts-title-font--large !default;
$kit-builder-selectedproducts-title-color: color(text) !default; // @new: $selected-products-title-color: $kit-builder-selectedproducts-title-color !default;
$kit-builder-selectedproducts-title-color--large: null !default; // @new: $selected-products-title-color--large: $kit-builder-selectedproducts-title-color--large !default;
$kit-builder-selectedproducts-title-text-transform: none !default; // @new: $selected-products-title-text-transform: $kit-builder-selectedproducts-title-text-transform !default;
$kit-builder-selectedproducts-title-text-transform--large: null !default; // @new: $selected-products-title-text-transform--large: $kit-builder-selectedproducts-title-text-transform--large !default;
$kit-builder-selectedproducts-title-margin: rem-calc(0 0 10) !default; // @new: $selected-products-title-margin: $kit-builder-selectedproducts-title-margin !default;
$kit-builder-selectedproducts-title-margin--large: 0 !default; // @new: $selected-products-title-margin--large: $kit-builder-selectedproducts-title-margin--large !default;
/// Total
$kit-builder-selectedproducts-total-justify-content: space-between !default; // @new: $selected-products-total-justify-content: $kit-builder-selectedproducts-total-justify-content !default;
$kit-builder-selectedproducts-total-justify-content--large: null !default; // @new: $selected-products-total-justify-content--large: $kit-builder-selectedproducts-total-justify-content--large !default;
$kit-builder-selectedproducts-total-padding: rem-calc(10) !default; // @new: $selected-products-total-padding: $kit-builder-selectedproducts-total-padding !default;
$kit-builder-selectedproducts-total-padding--large: null !default; // @new: $selected-products-total-padding--large: $kit-builder-selectedproducts-total-padding--large !default;
$kit-builder-selectedproducts-total-background: color(element-background) !default; // @new: $selected-products-total-background: $kit-builder-selectedproducts-total-background !default;
$kit-builder-selectedproducts-total-background--large: null !default; // @new: $selected-products-total-background--large: $kit-builder-selectedproducts-total-background--large !default;
$kit-builder-selectedproducts-total-font: 700 #{rem-calc(14)} / 1.2 $font-primary !default; // @new: $selected-products-total-font: $kit-builder-selectedproducts-total-font !default;
$kit-builder-selectedproducts-total-font--large: null !default; // @new: $selected-products-total-font--large: $kit-builder-selectedproducts-total-font--large !default;
$kit-builder-selectedproducts-total-color: color(text) !default; // @new: $selected-products-total-color: $kit-builder-selectedproducts-total-color !default;
$kit-builder-selectedproducts-total-color--large: null !default; // @new: $selected-products-total-color--large: $kit-builder-selectedproducts-total-color--large !default;
$kit-builder-selectedproducts-total-text-transform: uppercase !default; // @new: $selected-products-total-text-transform: $kit-builder-selectedproducts-total-text-transform !default;
$kit-builder-selectedproducts-total-text-transform--large: null !default; // @new: $selected-products-total-text-transform--large: $kit-builder-selectedproducts-total-text-transform--large !default;
$kit-builder-selectedproducts-total-margin: 0 !default; // @new: $selected-products-total-margin: $kit-builder-selectedproducts-total-margin !default;
$kit-builder-selectedproducts-total-margin--large: null !default; // @new: $selected-products-total-margin--large: $kit-builder-selectedproducts-total-margin--large !default;
// Actions
$kit-builder-selectedproducts-actions-margin: rem-calc(20 0 0) !default; // @new: $selected-products-actions-margin: $kit-builder-selectedproducts-actions-margin !default;
$kit-builder-selectedproducts-actions-margin--large: null !default; // @new: $selected-products-actions-margin--large: $kit-builder-selectedproducts-actions-margin--large !default;
// Carousel
$kit-builder-selectedproducts-carousel-margin: rem-calc(20 0 0) !default; // @new: $selected-products-carousel-margin: $kit-builder-selectedproducts-carousel-margin !default;
$kit-builder-selectedproducts-carousel-margin--large: null !default; // @new: $selected-products-carousel-margin--large: $kit-builder-selectedproducts-carousel-margin--large !default;
/// Error
$kit-builder-selectedproducts-error-flex--large: 1 0 auto !default; // @new: $selected-products-error-flex--large: $kit-builder-selectedproducts-error-flex--large !default;
$kit-builder-selectedproducts-error-margin: rem-calc(10 0 0) !default; // @new: $selected-products-error-margin: $kit-builder-selectedproducts-error-margin !default;
$kit-builder-selectedproducts-error-margin--large: rem-calc(0 20) !default; // @new: $selected-products-error-margin--large: $kit-builder-selectedproducts-error-margin--large !default;
$kit-builder-selectedproducts-error-order--large: -1 !default; // @new: $selected-products-error-order--large: $kit-builder-selectedproducts-error-order--large !default;
$kit-builder-selectedproducts-error-text-align: center !default; // @new: $selected-products-error-text-align: $kit-builder-selectedproducts-error-text-align !default;
$kit-builder-selectedproducts-error-text-align--large: $global-right !default; // @new: $selected-products-error-text-align--large: $kit-builder-selectedproducts-error-text-align--large !default;
/// Item
$kit-builder-selectedproducts-item-border: 1px solid color(organism-background) !default; // @new: $selected-products-item-border: $kit-builder-selectedproducts-item-border !default;
$kit-builder-selectedproducts-item-border--large: null !default; // @new: $selected-products-item-border--large: $kit-builder-selectedproducts-item-border--large !default;
$kit-builder-selectedproducts-item-padding: 0 !default; // @deleted https://bitbucket.e-loreal.com/projects/ECOM-LORA/repos/ecom-lora-ng/pull-requests/4557
$kit-builder-selectedproducts-item-padding--large: $kit-builder-selectedproducts-item-padding !default; // @deleted https://bitbucket.e-loreal.com/projects/ECOM-LORA/repos/ecom-lora-ng/pull-requests/4557
$kit-builder-selectedproducts-item-margin: rem-calc(0 0 5) !default; // @new: $selected-products-item-margin: $kit-builder-selectedproducts-item-margin !default;
$kit-builder-selectedproducts-item-margin--large: null !default; // @new: $selected-products-item-margin--large: $kit-builder-selectedproducts-item-margin--large !default;
$kit-builder-selectedproducts-item-height: rem-calc(135) !default; // @deleted https://bitbucket.e-loreal.com/projects/ECOM-LORA/repos/ecom-lora-ng/pull-requests/4557
$kit-builder-selectedproducts-item-height--large: rem-calc(170) !default; // @deleted https://bitbucket.e-loreal.com/projects/ECOM-LORA/repos/ecom-lora-ng/pull-requests/4557
/// Item active
$kit-builder-selectedproducts-item-active-border: 1px solid color(primary) !default; // @new: $selected-products-item-active-border: $kit-builder-selectedproducts-item-active-border !default;
$kit-builder-selectedproducts-item-active-border--large: null !default; // @new: $selected-products-item-active-border--large: $kit-builder-selectedproducts-item-active-border--large !default;
/// Item remove
$kit-builder-selectedproducts-item-remove-position: absolute !default; // @new: $selected-products-item-remove-position: $kit-builder-selectedproducts-item-remove-position !default;
$kit-builder-selectedproducts-item-remove-right: 0 !default; // @new: $selected-products-item-remove-right: $kit-builder-selectedproducts-item-remove-right !default;
$kit-builder-selectedproducts-item-remove-top: 0 !default; // @new: $selected-products-item-remove-top: $kit-builder-selectedproducts-item-remove-top !default;
$kit-builder-selectedproducts-item-remove-padding: rem-calc(8) !default; // @new: $selected-products-item-remove-padding: $kit-builder-selectedproducts-item-remove-padding !default;
$kit-builder-selectedproducts-item-remove-icon: "cross" !default; // @new: $selected-products-item-remove-icon: $kit-builder-selectedproducts-item-remove-icon !default;
$kit-builder-selectedproducts-item-remove-icon-color: color(primary) !default; // @new: $selected-products-item-remove-icon-color: $kit-builder-selectedproducts-item-remove-icon-color !default;
$kit-builder-selectedproducts-item-remove-icon-hover-color: color(primary-active) !default; // @new: $selected-products-item-remove-icon-hover-color: $kit-builder-selectedproducts-item-remove-icon-hover-color !default;
$kit-builder-selectedproducts-item-remove-icon-height: rem-calc(10) !default; // @new: $selected-products-item-remove-icon-height: $kit-builder-selectedproducts-item-remove-icon-height !default;
$kit-builder-selectedproducts-item-remove-icon-height--large: null !default; // @new: $selected-products-item-remove-icon-height--large: $kit-builder-selectedproducts-item-remove-icon-height--large !default;
$kit-builder-selectedproducts-item-remove-icon-width: rem-calc(10) !default; // @new: $selected-products-item-remove-icon-width: $kit-builder-selectedproducts-item-remove-icon-width !default;
$kit-builder-selectedproducts-item-remove-icon-width--large: null !default; // @new: $selected-products-item-remove-icon-width--large: $kit-builder-selectedproducts-item-remove-icon-width--large !default;
