$search-refinements-toggler-font: bold #{rem-calc(12)} / 1.5 $font-primary !default;
$search-refinements-toggler-font--large: 500 #{rem-calc(14)} / 1 $font-primary !default;
$search-refinements-toggler-padding: rem-calc(0 10 0 15) !default;
$search-refinements-toggler-padding--large: rem-calc(0 50 0 25) !default;
$search-refinements-toggler-border-radius--large: rem-calc(4) !default;
$search-refinements-toggler-border--large: 1px solid color(dark) !default;
$search-refinements-toggler-height--large: rem-calc(35) !default;
$search-refinements-toggler-text-transform: uppercase !default;
$search-refinements-toggler-after-content: "" !default;
$search-refinements-toggler-icon-transform: translateY(-50%) !default;
$search-refinements-toggler-color: color(text) !default;
$search-refinements-toggler-letter-spacing: null !default;
$search-refinements-toggler-content-icon: filter !default;
$search-refinements-toggler-content-icon-usesvg: true !default;
$search-refinements-toggler-content-color: color(dark) !default;
$search-refinements-toggler-after-size: rem-calc(18) !default;
$search-refinements-toggler-right: rem-calc(15) !default;
$search-refinements-toggler-after-right--large: rem-calc(25) !default;
// Quantity
$search-refinements-toggler-quantity-background: color(primary) !default;
$search-refinements-toggler-quantity-color: color(light) !default;
$search-refinements-toggler-quantity-size: rem-calc(20) !default;
$search-refinements-toggler-quantity-size--large: rem-calc(16) !default;
$search-refinements-toggler-quantity-margin: rem-calc(0 0 0 5) !default;
$search-refinements-toggler-applied-qty-border-radius: 50% !default;
// Applied Filters list
$search-refinements-toggler-applied-list-max-width: rem-calc(500) !default;
$search-refinements-toggler-applied-list-padding: rem-calc(0 10) !default;
$search-refinements-toggler-applied-list-font: #{rem-calc(14)} / 1.2 $font-primary !default;
$search-refinements-toggler-applied-list-color: color(text-secondary) !default;

@mixin lora-components-refinements-toggler {
    .c-refinements-toggler {
        align-items: center;
        cursor: pointer;
        display: flex;
        font: $search-refinements-toggler-font;
        padding: $search-refinements-toggler-padding;
        position: relative;
        text-transform: $search-refinements-toggler-text-transform;
        color: $search-refinements-toggler-color;
        letter-spacing: $search-refinements-toggler-letter-spacing;
        width: 100%;
        height: 100%;

        &::after {
            @include svg-icon($search-refinements-toggler-content-icon, $search-refinements-toggler-content-color, 100%, left, no-repeat, $usesvg: $search-refinements-toggler-content-icon-usesvg);

            content: $search-refinements-toggler-after-content;
            position: absolute;
            width: $search-refinements-toggler-after-size;
            height: $search-refinements-toggler-after-size;
            #{$global-right}: $search-refinements-toggler-right;
        }

        @include breakpoint(large) {
            font: $search-refinements-toggler-font--large;
            width: auto;
            padding: $search-refinements-toggler-padding--large;
            border-radius: $search-refinements-toggler-border-radius--large;
            border: $search-refinements-toggler-border--large;
            height: $search-refinements-toggler-height--large;

            &::after {
                #{$global-right}: $search-refinements-toggler-after-right--large;
            }
        }
    }

    .c-refinements-toggler__qty {
        background: $search-refinements-toggler-quantity-background;
        border-radius: $search-refinements-toggler-applied-qty-border-radius;
        color: $search-refinements-toggler-quantity-color;
        line-height: $search-refinements-toggler-quantity-size;
        text-align: center;
        margin: $search-refinements-toggler-quantity-margin;
        width: $search-refinements-toggler-quantity-size;
        z-index: 1; // stylelint-disable-line

        @include breakpoint(large) {
            line-height: $search-refinements-toggler-quantity-size--large;
            width: $search-refinements-toggler-quantity-size--large;
        }
    }

    .c-refinements-toggler__qty:empty {
        display: none;
    }

    .c-refinements-toggler__wrapper {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .c-refinements-toggler__applied-list {
        max-width: $search-refinements-toggler-applied-list-max-width;
        padding: $search-refinements-toggler-applied-list-padding;
        font: $search-refinements-toggler-applied-list-font;
        color: $search-refinements-toggler-applied-list-color;
    }
}
