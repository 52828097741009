$search-refinement-single-select-list-style: none !default;
$search-refinement-single-select-margin: 0 !default;
$search-refinement-single-select-padding: 0 !default;
$search-refinement-single-select-item-padding: rem-calc(14 0 0) !default;
$search-refinement-single-select-item-link-color: color(text-secondary) !default;
$search-refinement-single-select-item-link-text-decoration: none !default;
$search-refinement-single-select-item-link-selected-font-weight: bold !default;

@mixin lora-components-refinement-single-select {
    .c-refinement-single-select {
        list-style: $search-refinement-single-select-list-style;
        margin: $search-refinement-single-select-margin;
        padding: $search-refinement-single-select-padding;
    }

    .c-refinement-single-select__item + .c-refinement-single-select__item {
        padding: $search-refinement-single-select-item-padding;
    }

    .c-refinement-single-select__item-link {
        color: $search-refinement-single-select-item-link-color;
        display: inline-block;
        text-decoration: $search-refinement-single-select-item-link-text-decoration;
    }

    .c-refinement-single-select__item-link.m-selected {
        font-weight: $search-refinement-single-select-item-link-selected-font-weight;
    }
}
