/*------------------------------------*\
        #PRODUCT LISTING PAGE
\*------------------------------------*/
@import '../05-components/product/product-grid';
// Main PLP container
$plp-padding--large: rem-calc(0 0 50) !default;
// PLP Top container
$plp-top-size: null !default;
$plp-slot-top-size: null !default;
// Top Slot
$plp-slot-top-margin: rem-calc(0 0 15) !default;
$plp-slot-top-margin--large: rem-calc(0 0 35) !default;
// PLP Promotions
$plp-promotions-margin: rem-calc(15) auto !default;
$plp-promotions-margin--large: rem-calc(0 0 35) !default;
// Sidebar
$plp-sidebar-background: color(light) !default;
// Tools
$plp-tools-border: 1px solid color(border) !default;
$plp-tools-border-bottom--large: 1px solid color(border) !default;
$plp-tools-padding: null !default;
$plp-tools-padding--large: rem-calc(0 0 5) !default;
$plp-tools-border-radius: null !default;
$plp-tools-border-width: rem-calc(1 0) !default;
$plp-tools-align-items: stretch !default;
$plp-tools-align-items--large: baseline !default;
$plp-tools-min-height: rem-calc(40) !default;
// Tools without refinements
$plp-tools-no-refinements-heading-flex--large: 1 0 100% !default;
$plp-tools-no-refinements-heading-padding--large: rem-calc(7 0 10) !default;
$plp-tools-no-refinements-refinements-cta-flex--large: 1 0 0 !default;
// Results Count
$plp-results-count-color: color(text) !default;
$plp-results-count-font: #{rem-calc(12)} / 1.25 $font-primary !default;
$plp-results-count-margin: rem-calc(0 10 0 0) !default;
$plp-results-count-secondary-margin: 0 !default;
$plp-results-count-letter-spacing: null !default;
$plp-results-count-inline-justify-content: center !default;
$plp-results-count-inline-color: color(text-secondary) !default;
$plp-results-count-inline-border-right: 1px solid color(border) !default;
$plp-results-count-inline-flex: 1 0 0 !default;
// Sorting
$plp-sorting-flex: 1 0 0 !default;
$plp-sorting-border-right: 1px solid color(border) !default;
$plp-sorting-secondary-min-height: rem-calc(40) !default;
$plp-sorting-min-height: $plp-sorting-secondary-min-height !default;
$plp-sorting-field-text-transform: null !default;
$plp-sorting-field-border-radius: 0 !default;
$plp-sorting-min-width--large: rem-calc(180) !default;
// Heading
$plp-heading-flex--medium-down: 1 0 100% !default;
$plp-heading-flex--large: 1 0 0 !default;
$plp-heading-border-bottom--medium-down: 1px solid color(border) !default;
$plp-heading-padding--medium-down: rem-calc(15 20) !default;
// Refinements CTA
$plp-refinements-cta-flex: 1 0 0 !default;
$plp-refinements-cta-secondary-min-height: rem-calc(40) !default;
// Comparison Toggle
$plp-comparison-toggle-order: 2 !default;
$plp-comparison-toggle-border-top: 1px solid color(border) !default;
$plp-comparison-toggle-width: 100% !default;
$plp-comparison-toggle-padding: rem-calc(10 20) !default;
$plp-comparison-toggle-margin: null !default;
$plp-comparison-toggle-text-transform: uppercase !default;
$plp-comparison-toggle-font: null !default;
$plp-comparison-toggle-margin--large: rem-calc(0 25) !default;
$plp-comparison-toggle-secondary-margin--large: 0 !default;
// No refinements grid
$plp-no-refinements-product-grid-columns: 2 !default;
$plp-no-refinements-product-grid-columns--large: 4 !default;
// Product Results
$plp-product-results-padding-top: rem-calc(20) !default;
$plp-product-results-padding-top--large: $plp-product-results-padding-top !default;
// Pagination
$plp-pagination-margin: rem-calc(30 0 0) !default;
$plp-pagination-margin--large: rem-calc(40 0 0) !default;
// Recommendations
$plp-recommendations-separator: 1px solid color(border) !default;
$plp-recommendations-padding: rem-calc(20 0 0) !default;
$plp-recommendations-padding--large: rem-calc(55 0 0) !default;
$plp-recommendations-margin: rem-calc(30 0 0) !default;
$plp-recommendations-margin--large: rem-calc(5 0 0) !default;
// Back to Parent
$plp-back-to-parent-margin: rem-calc(20 0 0) !default;
// Bottom description
$plp-bottom-description-margin: rem-calc(20 0) !default;
$plp-bottom-description-margin--large: null !default;
$plp-bottom-description-padding: rem-calc(0) !default;
$plp-bottom-description-padding--large: null !default;
$plp-bottom-description-font: #{rem-calc(12)} / 1.6 $font-primary !default;
$plp-bottom-description-font--large: null !default;
$plp-bottom-description-text-align: null !default;
$plp-bottom-description-text-align--large: null !default;

@mixin lora-layout-plp {
    .l-plp {
        @include breakpoint(large) {
            padding: $plp-padding--large;
        }
    }

    .l-plp__top {
        @include breakpoint(large) {
            @if $plp-top-size != 'full' {
                @include layout;
            }
        }
    }

    .l-plp__slot-top {
        @include breakpoint(large) {
            @if $plp-top-size == 'full' and $plp-slot-top-size != 'full' {
                @include layout;
            }
        }
    }

    .l-plp__main {
        @include breakpoint(large) {
            @include layout;
        }

        &.m-no-refinements {
            @include lora-helper-product-grid-columns(
                $plp-no-refinements-product-grid-columns,
                $plp-no-refinements-product-grid-columns--large
            );

            .l-plp__content {
                @include breakpoint(large) {
                    @include cell;
                }
            }
        }
    }

    .l-plp__main-wrapper {
        @include grid;
    }

    .l-plp__sidebar {
        @include breakpoint(medium down) {
            background: $plp-sidebar-background;
            height: 100%;
            #{$global-left}: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: z(plpSidebar);
        }
        @include breakpoint(large) {
            @include cell(3 of 12);

            position: relative;
            z-index: z(search, sidebar);
        }
    }

    .l-plp:not(.m-refinements-panel-active) .l-plp__sidebar {
        @include breakpoint(medium down) {
            display: none;
        }
    }

    .l-plp__content {
        @include cell;

        display: flex;
        flex-flow: column;

        @include breakpoint(large) {
            @include cell(9 of 12);
        }
    }

    .l-plp__tools {
        align-items: $plp-tools-align-items;
        display: flex;
        order: 1;
        position: relative;

        @include breakpoint(medium down) {
            border: $plp-tools-border;
            border-width: $plp-tools-border-width;
            flex-wrap: wrap;
            padding: $plp-tools-padding;
            border-radius: $plp-tools-border-radius;
            min-height: $plp-tools-min-height;
        }
        @include breakpoint(large) {
            @if $plp-slot-top-size == 'full' {
                @include layout;
            }

            align-items: $plp-tools-align-items--large;
            padding: $plp-tools-padding--large;
            border-bottom: $plp-tools-border-bottom--large;
        }
    }

    .l-plp__tools.m-secondary {
        @include breakpoint(large) {
            justify-content: space-between;
        }

        .l-plp__results-count {
            margin: $plp-results-count-secondary-margin;
        }

        .l-plp__comparison-toggle {
            @include breakpoint(large) {
                margin: $plp-comparison-toggle-secondary-margin--large;
            }
        }

        .l-plp__refinements-cta {
            min-height: $plp-refinements-cta-secondary-min-height;
        }
    }

    .l-plp__tools.m-no-refinements {
        flex-wrap: wrap;

        .l-plp__heading {
            @include breakpoint(large) {
                flex: $plp-tools-no-refinements-heading-flex--large;
                padding: $plp-tools-no-refinements-heading-padding--large;
            }
        }

        .l-plp__refinements-cta {
            @include breakpoint(large) {
                flex: $plp-tools-no-refinements-refinements-cta-flex--large;
            }
        }
    }

    .l-plp__heading {
        @include breakpoint(medium down) {
            border-bottom: $plp-heading-border-bottom--medium-down;
            padding: $plp-heading-padding--medium-down;
            flex: $plp-heading-flex--medium-down;
        }
        @include breakpoint(large) {
            flex: $plp-heading-flex--large;
        }

        .c-refinements__category-name {
            border: none;
            margin: 0;
            padding: 0;
        }
    }

    .l-plp__refinements {
        @include breakpoint(medium down) {
            height: 100%;
        }
    }

    .l-plp__breadcrumbs,
    .l-plp__refinements-cta,
    .l-plp__results-count,
    .l-plp__result-count-inline,
    .l-plp__sorting,
    .l-plp__comparison-toggle {
        align-items: center;
        display: flex;
    }

    .l-plp__breadcrumbs {
        @include breakpoint(large) {
            @if $plp-slot-top-size == 'full' {
                @include layout;
            }
        }

        @include breakpoint(medium down) {
            display: none;
        }
    }

    .l-plp__refinements-cta:not(.m-large) {
        flex: $plp-refinements-cta-flex;

        @include breakpoint(large) {
            display: none;
        }
    }

    .l-plp__refinements-cta.m-large {
        @include breakpoint(medium down) {
            display: none;
        }
    }

    .l-plp__result-count-inline {
        color: $plp-results-count-inline-color;
        font: $plp-results-count-font;

        @include breakpoint(medium down) {
            flex: $plp-results-count-inline-flex;
            border-#{$global-right}: $plp-results-count-inline-border-right;
            justify-content: $plp-results-count-inline-justify-content;
        }

        @include breakpoint(large) {
            margin: $plp-results-count-margin;
        }
    }

    .l-plp__comparison-toggle {
        @include breakpoint(medium down) {
            order: $plp-comparison-toggle-order;
            border-top: $plp-comparison-toggle-border-top;
            width: $plp-comparison-toggle-width;
            padding: $plp-comparison-toggle-padding;
            margin: $plp-comparison-toggle-margin;
            text-transform: $plp-comparison-toggle-text-transform;
            font: $plp-comparison-toggle-font;
        }

        @include breakpoint(large) {
            margin: $plp-comparison-toggle-margin--large;
            white-space: nowrap;
        }

        &:empty {
            display: none;
        }
    }

    .l-plp__results-count {
        color: $plp-results-count-color;
        margin: $plp-results-count-margin;
        margin-#{$global-left}: auto;
        letter-spacing: $plp-results-count-letter-spacing;
        white-space: nowrap;

        @include breakpoint(medium down) {
            display: none;
        }

        .c-results-count {
            font: $plp-results-count-font;
        }
    }

    .l-plp__sorting {
        @include breakpoint(medium down) {
            flex: $plp-sorting-flex;
            border-#{$global-right}: $plp-sorting-border-right;
            min-height: $plp-sorting-min-height;
        }

        @include breakpoint(large) {
            min-width: $plp-sorting-min-width--large;
        }
    }

    .l-plp__pagination {
        order: 3;
        text-align: center;

        @include breakpoint(medium down) {
            @include layout;
        }

        .c-load-more,
        .c-pagination {
            margin: $plp-pagination-margin;

            @include breakpoint(large) {
                margin: $plp-pagination-margin--large;
            }
        }
    }

    .l-plp__back-to-parent {
        display: block;
        flex-grow: 1;
        margin: $plp-back-to-parent-margin;

        @include breakpoint(large) {
            display: none;
        }
    }

    .l-plp__product-results {
        order: 2;

        @include breakpoint(medium down) {
            @include layout;

            padding-top: $plp-product-results-padding-top;
        }
        @include breakpoint(large) {
            padding-top: $plp-product-results-padding-top--large;
        }
    }

    // Promotions
    .l-plp__promotions {
        @include breakpoint(medium down) {
            @include layout;

            margin: $plp-promotions-margin;
        }
        @include breakpoint(large) {
            margin: $plp-promotions-margin--large;
        }

        &:empty {
            display: none;
        }
    }

    // PLP components context styling
    .l-plp .c-category-cover {
        margin: $plp-slot-top-margin;

        @include breakpoint(large) {
            margin: $plp-slot-top-margin--large;
        }
    }

    .l-plp .c-sorting__field,
    .l-plp__sorting .c-select__placeholder {
        @include breakpoint(medium down) {
            border: 0;
            border-radius: $plp-sorting-field-border-radius;
            text-transform: $plp-sorting-field-text-transform;
        }
    }
    // EO PLP components context styling

    .l-plp__bottom-description {
        order: 4;

        @include text-style(
            $font: (
                small: $plp-bottom-description-font,
                large: $plp-bottom-description-font--large
            ),
            $margin: (
                small: $plp-bottom-description-margin,
                large: $plp-bottom-description-margin--large
            ),
            $padding: (
                small: $plp-bottom-description-padding,
                large: $plp-bottom-description-padding--large
            ),
            $text-align: (
                small: $plp-bottom-description-text-align,
                large: $plp-bottom-description-text-align--large
            )
        );
    }
}
