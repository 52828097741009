$search-plp-top-banner-background: color(secondary) !default;
$search-plp-top-banner-color: color(light) !default;
$search-plp-top-banner-background--large: transparent !default;
$search-plp-top-banner-color--large: color(text) !default;
$search-plp-top-banner-margin: 0 !default;
$search-plp-top-banner-margin--large: 0 !default;
$search-plp-top-banner-text-align: center !default;
// Category name
$search-plp-top-banner-category-name-margin: rem-calc(24 0 24) !default;
$search-plp-top-banner-category-name-font: #{rem-calc(18)} / 1.25 $font-primary !default;
$search-plp-top-banner-category-name-text-transform: uppercase !default;
$search-plp-top-banner-category-name-display: block !default;
$search-plp-top-banner-category-name-display--large: none !default;
$search-plp-top-banner-category-name-with-category-cover-display: block !default;
$search-plp-top-banner-category-name-with-category-cover-display--large: none !default;
$search-plp-top-banner-category-name-with-category-cover-color: color(light) !default;
// Category description
$search-plp-top-banner-category-description-margin: rem-calc(0 0 15) !default;
$search-plp-top-banner-category-description-font: #{rem-calc(12)} / 1.25 $font-primary !default;
$search-plp-top-banner-category-description-text-transform: none !default;
$search-plp-top-banner-category-description-display: block !default;
$search-plp-top-banner-category-description-display--large: none !default;
$search-plp-top-banner-category-description-with-category-cover-display: block !default;
$search-plp-top-banner-category-description-with-category-cover-display--large: none !default;
$search-plp-top-banner-category-description-with-category-cover-color: color(light) !default;
$search-plp-top-banner-category-description-letter-spacing: null !default;
// Results count
$search-plp-top-banner-results-count-color: color(text-secondary) !default;
$search-plp-top-banner-results-count-text-align: center !default;
$search-plp-top-banner-results-count-font: #{rem-calc(12)} / 1.25 $font-primary !default;
$search-plp-top-banner-results-count-margin: rem-calc(15 0 15) !default;
$search-plp-top-banner-results-count-text-transform: uppercase !default;
$search-plp-top-banner-results-count-display: block !default;
$search-plp-top-banner-results-count-display--large: none !default;
// Category Cover display
$search-plp-top-banner-category-cover-display: none !default;
$search-plp-top-banner-category-cover-display--large: block !default;

@mixin lora-components-plp-top-banner {
    .c-plp-top-banner {
        color: $search-plp-top-banner-color;
        background: $search-plp-top-banner-background;
        margin: $search-plp-top-banner-margin;

        @include breakpoint(medium down) {
            overflow: auto;
            text-align: $search-plp-top-banner-text-align;
        }
        @include breakpoint(large) {
            color: $search-plp-top-banner-color--large;
            background: $search-plp-top-banner-background--large;
            margin: $search-plp-top-banner-margin--large;
        }

        .c-category-cover:not(.m-unified) {
            display: $search-plp-top-banner-category-cover-display;

            @include breakpoint(large) {
                display: $search-plp-top-banner-category-cover-display--large;
            }
        }
    }

    .c-plp-top-banner__category-description {
        display: $search-plp-top-banner-category-description-display;
        font: $search-plp-top-banner-category-description-font;
        margin: $search-plp-top-banner-category-description-margin;
        text-transform: $search-plp-top-banner-category-description-text-transform;
        letter-spacing: $search-plp-top-banner-category-description-letter-spacing;

        @include breakpoint(large) {
            display: $search-plp-top-banner-category-description-display--large;
        }

        .c-category-cover + & {
            display: $search-plp-top-banner-category-description-with-category-cover-display;
            color: $search-plp-top-banner-category-description-with-category-cover-color;

            @include breakpoint(large) {
                display: $search-plp-top-banner-category-description-with-category-cover-display--large;
            }
        }
    }

    .c-plp-top-banner__category-name {
        display: $search-plp-top-banner-category-name-display;
        font: $search-plp-top-banner-category-name-font;
        margin: $search-plp-top-banner-category-name-margin;
        text-transform: $search-plp-top-banner-category-name-text-transform;

        @include breakpoint(large) {
            display: $search-plp-top-banner-category-name-display--large;
        }

        .c-category-cover + & {
            display: $search-plp-top-banner-category-name-with-category-cover-display;
            color: $search-plp-top-banner-category-name-with-category-cover-color;

            @include breakpoint(large) {
                display: $search-plp-top-banner-category-name-with-category-cover-display--large;
            }
        }
    }

    .c-plp-top-banner__results-count {
        color: $search-plp-top-banner-results-count-color;
        display: $search-plp-top-banner-results-count-display;
        margin: $search-plp-top-banner-results-count-margin;
        text-transform: $search-plp-top-banner-results-count-text-transform;
        text-align: $search-plp-top-banner-results-count-text-align;

        @include breakpoint(large) {
            display: $search-plp-top-banner-results-count-display--large;
        }

        .c-results-count {
            font: $search-plp-top-banner-results-count-font;
        }
    }
}
