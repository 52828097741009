@mixin lora-components-content-spot {
    .c-content-spot {
        display: block;
        height: 100%;

        picture {
            display: block;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
