@import "../swatch";
$search-refinement-swatches-list-style: none !default;
$search-refinement-swatches-margin: 0 !default;
$search-refinement-swatches-padding: 0 !default;
$search-refinement-swatches-group-name-color: color(text-secondary) !default;
$search-refinement-swatches-group-name-font: #{rem-calc(12)} / 1.2 $font-primary !default;
$search-refinement-swatches-group-name-margin: rem-calc(0 11) !default;
$search-refinement-swatches-group-name-margin--large: rem-calc(0 8) !default;
$search-refinement-swatches-group-name-padding: rem-calc(11 0 0) !default;
$search-refinement-swatches-group-name-padding--large: rem-calc(8 0 0) !default;
$search-refinement-swatches-item-margin: rem-calc(8) !default;
$search-refinement-swatches-item-margin--large: rem-calc(9) !default;
$search-refinement-swatches-group-margin: rem-calc(-11) !default;
$search-refinement-swatches-group-margin--large: rem-calc(-8) !default;
$search-refinement-swatches-focus-link-outline: 1px solid color(primary) !default;
@mixin lora-components-refinement-swatches {
    .c-refinement-swatches {
        list-style: $search-refinement-swatches-list-style;
        margin: $search-refinement-swatches-margin;
        padding: $search-refinement-swatches-padding;
    }

    .c-refinement-swatches__group-name {
        color: $search-refinement-swatches-group-name-color;
        font: $search-refinement-swatches-group-name-font;
        margin: $search-refinement-swatches-group-name-margin;
        padding: $search-refinement-swatches-group-name-padding;

        @include breakpoint(large) {
            margin: $search-refinement-swatches-group-name-margin--large;
            padding: $search-refinement-swatches-group-name-padding--large;
        }
    }

    .c-refinement-swatches__group {
        display: flex;
        flex-flow: row wrap;
        margin: $search-refinement-swatches-group-margin;

        @include breakpoint(large) {
            margin: $search-refinement-swatches-group-margin--large;
        }
    }

    .c-refinement-swatches__item {
        position: relative;
        margin: $search-refinement-swatches-item-margin;

        @include breakpoint(large) {
            margin: $search-refinement-swatches-item-margin--large;
        }
    }

    .c-refinement-swatches__input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &:focus + .c-refinement-swatches__link {
            outline: $search-refinement-swatches-focus-link-outline;
        }
    }

    .c-refinement-swatches__link {
        @include lora-helper-swatch;
        @include lora-helper-swatch-selected;
        @include lora-helper-swatch-size(default);
    }
}
