@import "../accordion";
// Deprecated components
@import "../../100-deprecated/search/search-refinement";

$search-refinement-border-bottom: 2px solid color(border) !default;
$search-refinement-border-top--large: 1px solid color(border) !default;
$search-refinement-border-bottom--large: 1px solid color(border) !default;
$search-refinement-content-background--medium-down: color(global-background) !default;
$search-refinement-content-padding--medium-down: rem-calc(15) !default;
$search-refinement-content-range-padding--medium-down: rem-calc(15) !default;
$search-refinement-content-padding--large: rem-calc(15 5 15) !default;
$search-refinement-content-max-height--large: null !default;
$search-refinement-content-overflow--large: null !default;
$search-refinement-content-wrapper-offset: rem-calc(10) !default;
$search-refinement-content-wrapper-margin--medium-down: 0 !default;
$search-refinement-content-wrapper-padding: 0 $search-refinement-content-wrapper-offset !default;
$search-refinement-content-wrapper-padding--medium-down: 0 !default;
$search-refinement-title-font: bold #{rem-calc(14)}/normal $font-primary !default;
$search-refinement-title-font--large: null !default;
$search-refinement-title-text-transform: none !default;
$search-refinement-title-text-transform--large: none !default;
$search-refinement-title-margin: 0 !default;
$search-refinement-title-padding: rem-calc(15) !default;
$search-refinement-title-padding--large: rem-calc(15 40 15 15) !default;
$search-refinement-title-accordion-control-collapsed-margin: rem-calc(0 5) !default;
$search-refinement-title-expanded-color: color(primary-active) !default;
$search-refinement-title-expanded-font: bold #{rem-calc(14)} / 1.4 $font-primary !default;
$search-refinement-title-expanded-padding: rem-calc(15) !default;
$search-refinement-title-expanded-padding--large: rem-calc(15 40 15 15) !default;
$search-refinement-title-expanded-border-bottom: 2px solid color(border) !default;
$search-refinement-title-expanded-border-bottom--medium-down: null !default;
$search-refinement-clear-margin: rem-calc(0 0 10) !default;
$search-refinement-clear-font: null !default;
$search-refinement-clear-text-transform: null !default;
$search-refinement-title-after-content: "chevron2-down" !default;
$search-refinement-title-after-content-usesvg: true !default;
$search-refinement-title-after-content-color: color(primary) !default;
$search-refinement-title-after-content-transform: rotateZ(0) !default;
$search-refinement-title-expanded-border-left: null !default;
$search-refinement-title-expanded-border-left--large: rem-calc(2) solid color(primary-active) !default;
$search-refinement-title-after-content-height: rem-calc(20) !default;
$search-refinement-title-after-content-margin-right: rem-calc(15) !default;
$search-refinement-title-expanded-after-content: "chevron2-top" !default;
$search-refinement-title-expanded-after-content-usesvg: true !default;
$search-refinement-content-wrapper-background-color: color(global-background) !default;
$search-refinement-applied-values-font: #{rem-calc(12)} / 1.4 $font-primary !default;
$search-refinement-applied-values-color: color(text-secondary) !default;
$search-refinement-title-text-text-transform: null !default;

@mixin lora-components-refinement {
    .c-refinement {
        border-bottom: $search-refinement-border-bottom;

        @include breakpoint(large) {
            border-bottom: none;
            border-top: $search-refinement-border-top--large;

            &:last-child {
                border-bottom: $search-refinement-border-bottom--large;
            }
        }

        &.m-expanded > {
            .c-refinement__title,
            .c-refinement__title-wrapper .c-refinement__title {
                @include lora-helper-accordion-control-expanded;

                // stylelint-disable selector-max-compound-selectors
                & > .c-accordion__icon::after {
                    @include svg-icon($search-refinement-title-expanded-after-content, $search-refinement-title-after-content-color, 100%, left, no-repeat, $usesvg: $search-refinement-title-expanded-after-content-usesvg);

                    content: '';
                }
            }

            .c-refinement__content-wrapper {
                @include lora-animation-expanded;
            }
        }

        &.m-range {
            .c-refinement__content {
                @include breakpoint(medium down) {
                    padding: $search-refinement-content-range-padding--medium-down;
                }
            }
        }
    }

    .c-refinement__content-wrapper {
        @include lora-animation-collapsed;

        padding: $search-refinement-content-wrapper-padding;
        background: $search-refinement-content-wrapper-background-color;

        .c-refinement.m-expanded-on-load & {
            max-height: none;
        }

        @include breakpoint(medium down) {
            margin: $search-refinement-content-wrapper-margin--medium-down;
            padding: $search-refinement-content-wrapper-padding--medium-down;
        }
    }

    .c-refinement__content {
        @include breakpoint(medium down) {
            background: $search-refinement-content-background--medium-down;
            padding: $search-refinement-content-padding--medium-down;
        }

        @include breakpoint(large) {
            padding: $search-refinement-content-padding--large;
            max-height: $search-refinement-content-max-height--large;
            overflow: $search-refinement-content-overflow--large;
        }
    }

    .c-refinement__title {
        @include lora-helper-accordion-control-collapsed;

        & > .c-accordion__icon::after {
            @include svg-icon($search-refinement-title-after-content, $search-refinement-title-after-content-color, 100%, left, no-repeat, $usesvg: $search-refinement-title-after-content-usesvg);

            transform: $search-refinement-title-after-content-transform;
            height: $search-refinement-title-after-content-height;
            margin-right: $search-refinement-title-after-content-margin-right;
            content: '';
        }

        width: 100%;
        margin: $search-refinement-title-margin;
        padding: $search-refinement-title-padding;
        font: $search-refinement-title-font;
        text-transform: $search-refinement-title-text-transform;

        @include breakpoint(large) {
            padding: $search-refinement-title-padding--large;
            font: $search-refinement-title-font--large;
            text-transform: $search-refinement-title-text-transform--large;
        }

        .c-refinement.m-expanded &,
        .c-refinement.m-selected & {
            color: $search-refinement-title-expanded-color;
            font: $search-refinement-title-expanded-font;
            padding: $search-refinement-title-expanded-padding;
            border-left: $search-refinement-title-expanded-border-left;

            @include breakpoint(large) {
                border-bottom: none;
                border-left: $search-refinement-title-expanded-border-left--large;
                padding: $search-refinement-title-expanded-padding--large;
            }
        }

        .c-refinement.m-expanded & {
            @include breakpoint(medium down) {
                border-bottom: $search-refinement-title-expanded-border-bottom--medium-down;
            }
        }
    }

    .c-refinement__title-text {
        pointer-events: none;
        position: relative;
        text-transform: $search-refinement-title-text-text-transform;
    }

    .c-refinement__applied-values {
        @include text-truncate;

        font: $search-refinement-applied-values-font;
        color: $search-refinement-applied-values-color;
    }

    .c-refinement__clear {
        display: inline-block;
        margin: $search-refinement-clear-margin;
        font: $search-refinement-clear-font;
        text-transform: $search-refinement-clear-text-transform;
    }
}
