// Layout
$category-cover-body-columns--large: 4 of 12 !default;
$category-cover-body-width--large: cell-width($category-cover-body-columns--large) !default;
$category-cover-body-padding: rem-calc(15 0) !default;
$category-cover-body-padding--large: null !default;
$category-cover-body-margin--large: rem-calc(0 50) !default;
// Title
$category-cover-title-font: 500 #{rem-calc(16)} / 1.25 $font-primary !default;
$category-cover-title-font--large: 500 #{rem-calc(24)} / 1.25 $font-primary !default;
$category-cover-title-color: color(light) !default;
$category-cover-title-color--large: color(light) !default;
$category-cover-title-text-transform: uppercase !default;
$category-cover-title-text-transform--large: null !default;
$category-cover-title-margin: rem-calc(0 0 5) !default;
$category-cover-title-margin--large: rem-calc(0 0 8) !default;
// Description
$category-cover-description-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$category-cover-description-font--large: #{rem-calc(12)} / 1.5 $font-primary !default;
$category-cover-description-color: color(light) !default;
$category-cover-description-color--large: color(light) !default;
$category-cover-description-text-transform: none !default;
$category-cover-description-text-transform--large: none !default;
$category-cover-description-letter-spacing: null !default;

@mixin lora-components-category-cover {
    .c-category-cover {
        position: relative;

        &.m-caption-right--large {
            .c-category-cover__caption {
                @include breakpoint(large) {
                    text-align: $global-right;
                }
            }
        }

        &.m-caption-left--large {
            .c-category-cover__caption {
                @include breakpoint(large) {
                    text-align: $global-left;
                }
            }
        }

        &.m-caption-center--large {
            .c-category-cover__caption {
                @include breakpoint(large) {
                    text-align: center;
                }
            }
        }

        &.m-caption-top--large {
            .c-category-cover__caption {
                @include breakpoint(large) {
                    top: 0;
                    transform: translateX(-50%);
                }
            }
        }

        &.m-caption-bottom--large {
            .c-category-cover__caption {
                @include breakpoint(large) {
                    bottom: 0;
                    top: auto;
                    transform: translateX(-50%);
                }
            }
        }

        &.m-caption-only--medium-down {
            .c-category-cover__caption {
                @include breakpoint(medium down) {
                    position: static;
                    transform: none;
                }
            }

            .c-category-cover__image {
                @include breakpoint(medium down) {
                    display: none;
                }
            }
        }
    }

    .c-category-cover__image {
        &.m-link {
            @include breakpoint(large) {
                pointer-events: none;
            }
        }

        img {
            width: 100%;
        }
    }

    .c-category-cover__caption {
        @include layout;

        width: 100%;

        &:not(.m-no-image) {
            @include absolute-center;
        }
    }

    .c-category-cover__body {
        padding: $category-cover-body-padding;

        @include breakpoint(large) {
            display: inline-block;
            padding: $category-cover-body-padding--large;
            margin: $category-cover-body-margin--large;
            width: $category-cover-body-width--large;
        }

        & > :last-child {
            margin-bottom: 0;
        }
    }

    .c-category-cover__title {
        @include text-style(
            $font: (
                small: $category-cover-title-font,
                large: $category-cover-title-font--large
            ),
            $margin: (
                small: $category-cover-title-margin,
                large: $category-cover-title-margin--large
            ),
            $text-transform: (
                small: $category-cover-title-text-transform,
                large: $category-cover-title-text-transform--large
            ),
            $color: (
                small: $category-cover-title-color,
                large: $category-cover-title-color--large
            )
        );
    }

    .c-category-cover__description {
        @include text-style(
            $font: (
                small: $category-cover-description-font,
                large: $category-cover-description-font--large
            ),
            $letter-spacing: (
                small: $category-cover-description-letter-spacing
            ),
            $text-transform: (
                small: $category-cover-description-text-transform,
                large: $category-cover-description-text-transform--large
            ),
            $color: (
                small: $category-cover-description-color,
                large: $category-cover-description-color--large
            )
        );
    }
}
