@import "../form/form";
@import "../form/select";

$search-sorting-field-font-family: null !default;
$search-sorting-field-font-weight: null !default;
$search-sorting-field-text-transform: null !default;
$search-sorting-field-text-transform--medium-down: null !default;
$search-sorting-label-text-transform--medium-down: uppercase !default;
$search-sorting-label-color--medium-down: color(dark) !default;
$search-sorting-label-font--medium-down: bold #{rem-calc(12)} / 1.5 $font-primary !default;
$search-sorting-label-top--medium-down: rem-calc(11) !default;
$search-sorting-field-font--medium-down: #{rem-calc(12)} / 1.2 $font-primary !default;
$search-sorting-field-color--medium-down: color(text-secondary) !default;
$search-sorting-field-padding-top--medium-down: rem-calc(15) !default;
$search-sorting-field-padding-left--medium-down: null !default;
$search-sorting-field-height--medium-down: 100% !default;
$search-sorting-field-hover-font-weight--medium-down: null !default;

@mixin lora-components-sorting {
    .c-sorting {
        flex-grow: 1;
        height: 100%;
        
        &:not(.m-customized) {
            @include select-element;
        }
    }

    .c-sorting__field {
        @include select-control;

        font-family: $search-sorting-field-font-family;
        font-weight: $search-sorting-field-font-weight;
        text-transform: $search-sorting-field-text-transform;

        &:hover {
            @include breakpoint(medium down) {
                font-weight: $search-sorting-field-hover-font-weight--medium-down;
            }
        }

        @include breakpoint(medium down) {
            font: $search-sorting-field-font--medium-down;
            color: $search-sorting-field-color--medium-down;
            padding-top: $search-sorting-field-padding-top--medium-down;
            padding-left: $search-sorting-field-padding-left--medium-down;
            height: $search-sorting-field-height--medium-down;
            text-transform: $search-sorting-field-text-transform--medium-down;
        }
    }

    .c-sorting__label.c-text-field__label.m-float {
        @include breakpoint(medium down) {
            top: $search-sorting-label-top--medium-down;
            text-transform: $search-sorting-label-text-transform--medium-down;
        }

        &.c-select__label {
            @include breakpoint(medium down) {
                color: $search-sorting-label-color--medium-down;
                font: $search-sorting-label-font--medium-down;
            }
        }
    }
}