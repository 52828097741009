$search-refinement-category-list-style: none !default;
$search-refinement-category-margin: 0 !default;
$search-refinement-category-padding: rem-calc(0 0 10) !default;
$search-refinement-category-item-margin: 0 !default;
$search-refinement-category-item-parent-hide: false !default;
$search-refinement-category-item-parent-icon: '←' !default;
$search-refinement-category-item-parent-icon-margin: rem-calc(0 5 0 0) !default;
$search-refinement-category-link-color: color(text) !default;
$search-refinement-category-link-font: #{rem-calc(14)}/1.5 $font-primary !default;
$search-refinement-category-link-padding: rem-calc(8 0) !default;
$search-refinement-category-link-text-decoration: none !default;
$search-refinement-category-link-selected-font-weight: bold !default;
$search-refinement-category-link-selected-color: null !default;
$search-refinement-category-link-selected-text-decoration: null !default;
$search-refinement-category-link-letter-spacing: null !default;

$search-refinement-category-item-arrow-icon: "" !default;
$search-refinement-category-item-arrow-icon-usesvg: true !default;
$search-refinement-category-item-arrow-color: $search-refinement-category-link-color !default;
$search-refinement-category-item-arrow-background-size: 100% !default;
$search-refinement-category-item-arrow-size: 8px !default;
$search-refinement-category-item-arrow-right-offset: rem-calc(0) !default;
$search-refinement-category-item-padding: 0 !default;
$search-refinement-category-link-text-transform: none !default;
$search-refinement-category-max-height--large: null !default;
$search-refinement-category-overflow--large: null !default;

@mixin lora-components-refinement-category {
    .c-refinement-category {
        list-style: $search-refinement-category-list-style;
        margin: $search-refinement-category-margin;
        padding: $search-refinement-category-padding;

        @include breakpoint(large) {
            max-height: $search-refinement-category-max-height--large;
            overflow: $search-refinement-category-overflow--large;
        }
    }

    .c-refinement-category__item {
        position: relative;
        margin: $search-refinement-category-item-margin;

        &:not(:first-child) {
            padding: $search-refinement-category-item-padding;
        }

        @if ($search-refinement-category-item-arrow-icon  != "") {
            &:not(.m-parent)::after {
                @include vertical-center;
                @include svg-icon($search-refinement-category-item-arrow-icon, $search-refinement-category-item-arrow-color, $search-refinement-category-item-arrow-background-size, left, no-repeat, $usesvg: $search-refinement-category-item-arrow-icon-usesvg);

                content: '';
                width: $search-refinement-category-item-arrow-size;
                height: $search-refinement-category-item-arrow-size;
                #{$global-right}: $search-refinement-category-item-arrow-right-offset;
                pointer-events: none;
            }
        }
    }

    .c-refinement-category__link {
        color: $search-refinement-category-link-color;
        display: block;
        font: $search-refinement-category-link-font;
        padding: $search-refinement-category-link-padding;
        text-decoration: $search-refinement-category-link-text-decoration;
        text-transform: $search-refinement-category-link-text-transform;
        letter-spacing: $search-refinement-category-link-letter-spacing;
    }

    .c-refinement-category__link.m-selected {
        font-weight: $search-refinement-category-link-selected-font-weight;
        color: $search-refinement-category-link-selected-color;
        text-decoration: $search-refinement-category-link-selected-text-decoration;
    }

    .c-refinement-category__item.m-parent {
        @if ($search-refinement-category-item-parent-hide) {
            display: none;
        }

        .c-refinement-category__link::before {
            content: $search-refinement-category-item-parent-icon;
            margin: $search-refinement-category-item-parent-icon-margin;
        }
    }
}
