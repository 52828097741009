$search-plp-top-banner-category-cover-display: block;
$search-plp-top-banner-color: color(dark);
$search-plp-top-banner-color--large: color(light);
$search-plp-top-banner-category-name-with-category-cover-color: color(dark);
$search-plp-top-banner-category-description-display: none;
$search-plp-top-banner-category-name-with-category-cover-display: none;
$search-plp-top-banner-text-align: center;
$search-plp-top-banner-category-name-margin: rem-calc(17 12 2);

$search-plp-top-banner-category-name-display: block;
$search-plp-top-banner-results-count-display: block;
$search-plp-top-banner-category-name-margin: rem-calc(10 0 0);
$search-plp-top-banner-results-count-margin: rem-calc(0 0 15);
$search-plp-top-banner-results-count-color: color(primary);
$search-plp-top-banner-results-count-text-transform: none;
$search-plp-top-banner-results-count-font: #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$search-plp-top-banner-category-name-font: #{rem-calc(18)} / #{rem-calc(20)} $font-primary;
$search-plp-top-banner-background: color(light);
$search-plp-top-banner-color: color(primary);
$search-plp-top-banner-background--large: color(secondary);
$search-plp-top-banner-color--large: color(primary);

@import "@lora/05-components/search/search-plp-top-banner";